(function () {
    'use strict';

    angular.module("linkApp")
        .config(linkAppRoutesConfig);

    linkAppRoutesConfig.$inject = ['$stateProvider', '$urlRouterProvider', 'routesConstant'];

    function linkAppRoutesConfig($stateProvider, $urlRouterProvider, routesConstant) {

        var LINK = routesConstant.LINK;

        var makeResolve = function () {
            return {
                allPermissions: ['CommonService', function (CommonService) {
                    var promise = CommonService.getAllPermissions()
                        .then(function (types) {
                            return loadPermissions(types)
                        });
                    angular.module('rolesConfig').value("allPermissions", promise);
                    return promise;
                }],
                allRoles: ['CommonService', function (CommonService) {
                    var promise = CommonService.getAllRoles()
                        .then(function (types) {
                            return loadRoles(types)
                        });
                    angular.module('rolesConfig').value("allRoles", promise);
                    return promise;
                }]
            }
        };

        $stateProvider

            .state(LINK.MAIN.stateName, {
                url: LINK.MAIN.url,
                controller: 'linkMainController',
                templateUrl: '/static/templates/link/linkMain.html',

            })
            .state(LINK.DASHBOARD.stateName, {
                url: LINK.DASHBOARD.url,
                views: {
                    "content": {
                        controller: 'LinkDashboardController',
                        templateUrl: '/static/templates/link/linkDashboard.html',
                    }
                },
                resolve: makeResolve()
            })
    }
})();
